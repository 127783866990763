import Joi from 'joi';
import {getValidationMessages} from '../../validation';

export class FormHelper {
  static buildRule(schema: Joi.Schema, locale?: string) {
    const options: any = {};
    if (locale) {
      options.messages = getValidationMessages(locale);
    }
    return [(input: any) => {
      const result = schema.validate(input, options);
      return result.error?.message || true;
    }];
  }

  static buildRules(schema: { [name: string]: Joi.Schema }, locale: string): any {
    return Object.keys(schema).reduce((obj, key) => Object.assign(obj, {[key]: FormHelper.buildRule(schema[key], locale)}), {});
  }
}
