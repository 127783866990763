import Joi from 'joi';

const constraints = {
  locale: {maxLength: 2},
};

export const educationValidation = {
  constraints,
  schema: Joi.object({}),
};
