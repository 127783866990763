import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {path: '/', redirect: 'users'},
  {path: '/home', name: 'home', component: () => import( /* webpackChunkName: "home.page" */ './pages/home.page.vue')},
  {path: '/files', name: 'files', component: () => import( /* webpackChunkName: "files.page" */ './pages/dev/files.page.vue')},
  {path: '/dev/ksa', name: 'ksa', component: () => import( /* webpackChunkName: "ksa-sheet.page" */ './pages/dev/ksa-sheet.page.vue')},
  {path: '/dev/mongo', name: 'mongo', component: () => import( /* webpackChunkName: "mongo.page" */ './pages/dev/mongo.page.vue')},
  {path: '/dev/mongo/:collection', name: 'mongo-collection', component: () => import( /* webpackChunkName: "mongo-collection.page" */ './pages/dev/mongo-collection.page.vue')},
  // Users
  {path: '/users', name: 'users', component: () => import( /* webpackChunkName: "users.page" */ './pages/user/users.page.vue')},
  {path: '/users/create', name: 'user-create', component: () => import( /* webpackChunkName: "user-create.page" */ './pages/user/user-create.page.vue')},
  {path: '/users/:id', name: 'user-details', component: () => import( /* webpackChunkName: "user-details.page" */ './pages/user/user-details.page.vue')},

  // Countries
  {path: '/countries', name: 'countries', component: () => import( /* webpackChunkName: "countries.page" */ './pages/country/countries.page.vue')},

  // Organizations
  {path: '/organizations', name: 'organizations', component: () => import( /* webpackChunkName: "organizations.page" */ './pages/organization/organizations.page.vue')},
  {
    path: '/organizations/create',
    name: 'organization-create',
    component: () => import( /* webpackChunkName: "organization-create.page" */ './pages/organization/organization-create.page.vue'),
  },
  {
    path: '/organizations/:id',
    name: 'organization-details',
    component: () => import( /* webpackChunkName: "organization-details.page" */ './pages/organization/organization-details.page.vue'),
  },
  {
    path: '/organizations/:id/edit',
    name: 'organization-edit',
    component: () => import( /* webpackChunkName: "organization-edit.page" */ './pages/organization/organization-edit.page.vue'),
  },

  // Examination Authorities
  {
    path: '/examination-authorities',
    name: 'examination-authorities',
    component: () => import( /* webpackChunkName: "examination-authorities.page" */ './pages/examination-authority/examination-authorities.page.vue'),
  },
  {
    path: '/examination-authority/:id',
    name: 'examination-authority-details',
    component: () => import( /* webpackChunkName: "examination-authority-details.page" */ './pages/examination-authority/examination-authority-details.page.vue'),
  },

  // Analysis
  {path: '/analysis', name: 'analysis', component: () => import( /* webpackChunkName: "analysis.page" */ './pages/analysis.page.vue')},

  // Cache
  {path: '/cache', name: 'cache', component: () => import( /* webpackChunkName: "cache.page" */ './pages/dev/cache.page.vue')},

  // Orders
  {path: '/orders', name: 'orders', component: () => import( /* webpackChunkName: "orders.page" */ './pages/order/orders.page.vue')},
  {path: '/orders/:id', name: 'order-details', component: () => import( /* webpackChunkName: "order-details.page" */ './pages/order/order-details.page.vue')},
  {path: '/overdue-orders', name: 'overdue-orders', component: () => import( /* webpackChunkName: "overdue-orders.page" */ './pages/order/overdue-orders.page.vue')},

  // Products
  {path: '/products', name: 'products', component: () => import( /* webpackChunkName: "products.page" */ './pages/product/products.page.vue')},
  {path: '/products/create', name: 'product-create', component: () => import( /* webpackChunkName: "product-create.page" */ './pages/product/product-create.page.vue')},
  {path: '/products/:id', name: 'product-details', component: () => import( /* webpackChunkName: "product-details.page" */ './pages/product/product-details.page.vue')},
  {path: '/products/:id/edit', name: 'product-edit', component: () => import( /* webpackChunkName: "product-edit.page" */ './pages/product/product-edit.page.vue')},

  // Sources
  {path: '/sources', name: 'sources', component: () => import( /* webpackChunkName: "sources.page" */ './pages/source/sources.page.vue')},
  {path: '/sources/create', name: 'source-create', component: () => import( /* webpackChunkName: "source-create.page" */ './pages/source/source-create.page.vue')},
  {path: '/sources/:id', name: 'source-details', component: () => import( /* webpackChunkName: "source-details.page" */ './pages/source/source-details.page.vue')},
  {path: '/sources/:id/edit', name: 'source-edit', component: () => import( /* webpackChunkName: "source-edit.page" */ './pages/source/source-edit.page.vue')},

  // Invoices
  {path: '/invoices', name: 'invoices', component: () => import( /* webpackChunkName: "invoices.page" */ './pages/invoice/invoices.page.vue')},
  {path: '/invoices/create', name: 'invoice-create', component: () => import( /* webpackChunkName: "invoice-create.page" */ './pages/invoice/invoice-create.page.vue')},
  {path: '/invoices/:id', name: 'invoice-details', component: () => import( /* webpackChunkName: "invoice-details.page" */ './pages/invoice/invoice-details.page.vue')},

  // Licences
  {path: '/licences', name: 'licences', component: () => import( /* webpackChunkName: "licences.page" */ './pages/licence/licences.page.vue')},
  {path: '/licences/create', name: 'licence-create', component: () => import( /* webpackChunkName: "licence-create.page" */ './pages/licence/licence-create.page.vue')},
  {path: '/licences/:id', name: 'licence-details', component: () => import( /* webpackChunkName: "licence-details.page" */ './pages/licence/licence-details.page.vue')},
  {path: '/licences/:id/edit', name: 'licence-edit', component: () => import( /* webpackChunkName: "licence-edit.page" */ './pages/licence/licence-edit.page.vue')},

  // Events
  {path: '/events', name: 'events', component: () => import( /* webpackChunkName: "events.page" */ './pages/event/events.page.vue')},
  {path: '/events/:id', name: 'event-details', component: () => import( /* webpackChunkName: "event-details.page" */ './pages/event/event-details.page.vue')},

  // Educations
  {path: '/educations', name: 'educations', component: () => import( /* webpackChunkName: "education.page" */ './pages/education/education.page.vue')},
  {path: '/educations/create', name: 'education-create', component: () => import( /* webpackChunkName: "education-create.page" */ './pages/education/education-create.page.vue')},
  {path: '/educations/:id', name: 'education-details', component: () => import( /* webpackChunkName: "education-details.page" */ './pages/education/education-details.page.vue')},
  {path: '/educations/:id/edit', name: 'education-edit', component: () => import( /* webpackChunkName: "education-edit.page" */ './pages/education/education-edit.page.vue')},

  // Modules
  {path: '/modules', name: 'modules', component: () => import( /* webpackChunkName: "module.page" */ './pages/module/module.page.vue')},
  {path: '/modules/create', name: 'module-create', component: () => import( /* webpackChunkName: "module-create.page" */ './pages/module/module-create.page.vue')},
  {path: '/modules/:id', name: 'module-details', component: () => import( /* webpackChunkName: "module-details.page" */ './pages/module/module-details.page.vue')},
  {path: '/modules/:id/edit', name: 'module-edit', component: () => import( /* webpackChunkName: "module-edit.page" */ './pages/module/module-edit.page.vue')},

  {path: '/tests/:id', name: 'test-details', component: () => import( /* webpackChunkName: "test-details.page" */ './pages/test/test-details.page.vue')},
  // {path: '/tests/:id/edit', name: 'test-edit', component: () => import( /* webpackChunkName: "test-edit.page" */ './pages/test/test-edit.page.vue')},

  // Todos
  {path: '/todos', name: 'todos', component: () => import( /* webpackChunkName: "todos.page" */ './pages/todo/todos.page.vue')},
  {path: '/todos/:id', name: 'todo-details', component: () => import( /* webpackChunkName: "todo-details.page" */ './pages/todo/todo-details.page.vue')},

  // Tag
  {path: '/tags', name: 'tags', component: () => import( /* webpackChunkName: "tags.page" */ './pages/tag/tags.page.vue')},
  {path: '/tags/:id', name: 'tag-details', component: () => import( /* webpackChunkName: "tag-details.page" */ './pages/tag/tag-details.page.vue')},

  // Resources
  {path: '/resources', name: 'resources', component: () => import( /* webpackChunkName: "resources.page" */ './pages/resource/resources.page.vue')},
  {path: '/resources/create', name: 'resource-create', component: () => import( /* webpackChunkName: "resource-create.page" */ './pages/resource/resource-create.page.vue')},
  {path: '/resources/:id', name: 'resource-details', component: () => import( /* webpackChunkName: "resource-details.page" */ './pages/resource/resource-details.page.vue')},

  // Tickets
  {path: '/tickets', name: 'tickets', component: () => import( /* webpackChunkName: "tickets.page" */ './pages/ticket/tickets.page.vue')},
  {path: '/tickets/create', name: 'ticket-create', component: () => import( /* webpackChunkName: "ticket-create.page" */ './pages/ticket/ticket-create.page.vue')},
  {path: '/tickets/:id', name: 'ticket-details', component: () => import( /* webpackChunkName: "ticket-details.page" */ './pages/ticket/ticket-details.page.vue')},
  {path: '/tickets/:id/edit', name: 'ticket-edit', component: () => import( /* webpackChunkName: "ticket-edit.page" */ './pages/ticket/ticket-edit.page.vue')},

  // Tasks
  {path: '/tasks', name: 'tasks', component: () => import( /* webpackChunkName: "tasks.page" */ './pages/task/tasks.page.vue')},
  {path: '/tasks/:id', name: 'task-details', component: () => import( /* webpackChunkName: "task-details.page" */ './pages/task/task-details.page.vue')},
  // {path: '/tasks/create', name: 'task-create', component: () => import( /* webpackChunkName: "task-create.page" */ './pages/task/task-create.page.vue')},
  // {path: '/tasks/:id/edit', name: 'task-edit', component: () => import( /* webpackChunkName: "task-edit.page" */ './pages/task/task-edit.page.vue')},

  // Comments
  {path: '/comments', name: 'comments', component: () => import( /* webpackChunkName: "comments.page" */ './pages/comment/comments.page.vue')},
  {path: '/comments/:id', name: 'comment-details', component: () => import( /* webpackChunkName: "comment-details.page" */ './pages/comment/comment-details.page.vue')},
  // {path: '/comments/:id/edit', name: 'comment-edit', component: () => import( /* webpackChunkName: "comment-edit.page" */ './pages/comments/comment-edit.page.vue')},

  // Public Comments
  {path: '/public-comments', name: 'public-comments', component: () => import( /* webpackChunkName: "public-comments.page" */ './pages/public-comment/public-comments.page.vue')},
  {
    path: '/public-comments/:id',
    name: 'public-comment-details',
    component: () => import( /* webpackChunkName: "public-comment-details.page" */ './pages/public-comment/public-comment-details.page.vue'),
  },
  // {path: '/comments/:id/edit', name: 'comment-edit', component: () => import( /* webpackChunkName: "comment-edit.page" */ './pages/comments/comment-edit.page.vue')},

  // Notes
  {path: '/notes', name: 'notes', component: () => import( /* webpackChunkName: "notes.page" */ './pages/note/notes.page.vue')},
  {path: '/notes/:id', name: 'note-details', component: () => import( /* webpackChunkName: "note-details.page" */ './pages/note/note-details.page.vue')},
  // {path: '/notes/:id/edit', name: 'note-edit', component: () => import( /* webpackChunkName: "note-edit.page" */ './pages/notes/note-edit.page.vue')},

  // Questions
  {path: '/questions', name: 'questions', component: () => import( /* webpackChunkName: "questions.page" */ './pages/question/questions.page.vue')},
  {path: '/questions/create', name: 'question-create', component: () => import( /* webpackChunkName: "question-create.page" */ './pages/question/question-create.page.vue')},
  {path: '/questions/import', name: 'question-import', component: () => import( /* webpackChunkName: "question-import.page" */ './pages/question/question-import.page.vue')},
  {path: '/questions/:id', name: 'question-details', component: () => import( /* webpackChunkName: "question-details.page" */ './pages/question/question-details.page.vue')},

  // QuestionCatalogue
  {
    path: '/question-catalogues',
    name: 'question-catalogues',
    component: () => import( /* webpackChunkName: "question-catalogues.page" */ './pages/question-catalogue/question-catalogues.page.vue'),
  },
  {
    path: '/question-catalogues/create',
    name: 'question-catalogue-create',
    component: () => import( /* webpackChunkName: "question-catalogue-create.page" */ './pages/question-catalogue/question-catalogue-create.page.vue'),
  },
  {
    path: '/question-catalogues/:id',
    name: 'question-catalogue-details',
    component: () => import( /* webpackChunkName: "question-catalogue-details.page" */ './pages/question-catalogue/question-catalogue-details.page.vue'),
  },

  {
    path: '/questionnaire-templates',
    name: 'questionnaire-templates',
    component: () => import( /* webpackChunkName: "questionnaire-templates.page" */ './pages/questionnaire-template/questionnaire-templates.page.vue'),
  },
  {
    path: '/questionnaire-templates/create',
    name: 'questionnaire-template-create',
    component: () => import( /* webpackChunkName: "questionnaire-template-create.page" */ './pages/questionnaire-template/questionnaire-template-create.page.vue'),
  },
  {
    path: '/questionnaire-templates/:id',
    name: 'questionnaire-template-details',
    component: () => import( /* webpackChunkName: "questionnaire-template-details.page" */ './pages/questionnaire-template/questionnaire-template-details.page.vue'),
  },
  {
    path: '/questionnaire-templates/:id/edit',
    name: 'questionnaire-template-edit',
    component: () => import( /* webpackChunkName: "questionnaire-template-edit.page" */ './pages/questionnaire-template/questionnaire-template-edit.page.vue'),
  },

  // Questionnaires
  {
    path: '/questionnaires',
    name: 'questionnaires',
    component: () => import( /* webpackChunkName: "questionnaires.page" */ './pages/questionnaire/questionnaires.page.vue'),
  },
  {
    path: '/questionnaires/:id',
    name: 'questionnaire-details',
    component: () => import( /* webpackChunkName: "questionnaire-details.page" */ './pages/questionnaire/questionnaire-details.page.vue'),
  },

  // Content / Scripts
  {path: '/scripts', name: 'scripts', component: () => import( /* webpackChunkName: "scripts.page" */ './pages/script/scripts.page.vue')},
  {path: '/scripts/create', name: 'script-create', component: () => import( /* webpackChunkName: "script-create.page" */ './pages/script/script-create.page.vue')},
  {path: '/scripts/:id', name: 'script-details', component: () => import( /* webpackChunkName: "script-details.page" */ './pages/script/script-details.page.vue')},

  // Content / Content-Blocks
  {
    path: '/content-blocks',
    name: 'content-blocks',
    component: () => import( /* webpackChunkName: "content-blocks.page" */ './pages/content-block/content-blocks.page.vue'),
  },
  {
    path: '/content-blocks/create',
    name: 'content-block-create',
    component: () => import( /* webpackChunkName: "content-block-create.page" */ './pages/content-block/content-block-create.page.vue'),
  },
  {
    path: '/content-blocks/:id',
    name: 'content-block-details',
    component: () => import( /* webpackChunkName: "content-block-details.page" */ './pages/content-block/content-block-details.page.vue'),
  },

  {path: '/notifications', name: 'notifications', component: () => import( /* webpackChunkName: "notifications.page" */ './pages/notifications.page.vue')},
  {path: '/my-tasks', name: 'my-tasks', component: () => import( /* webpackChunkName: "my-tasks.page" */ './pages/task/my-tasks.page.vue')},

  // Learning Objectives
  {
    path: '/learning-objectives',
    name: 'learning-objectives',
    component: () => import( /* webpackChunkName: "learning-objectives.page" */ './pages/learning-objective/learning-objectives.page.vue'),
  }, {
    path: '/learning-objectives/create',
    name: 'learning-objective-create',
    component: () => import( /* webpackChunkName: "learning-objective-create.page" */ './pages/learning-objective/learning-objective-create.page.vue'),
  }, {
    path: '/learning-objectives/:id',
    name: 'learning-objective-details',
    component: () => import( /* webpackChunkName: "learning-objective-details.page" */ './pages/learning-objective/learning-objective-details.page.vue'),
  }, {
    path: '/learning-objectives/:id/edit',
    name: 'learning-objective-edit',
    component: () => import( /* webpackChunkName: "learning-objective-edit.page" */ './pages/learning-objective/learning-objective-edit.page.vue'),
  },

  // Learning Objective Trees
  {
    path: '/learning-objective-trees',
    name: 'learning-objective-trees',
    component: () => import( /* webpackChunkName: "learning-objectives.page" */ './pages/learning-objective-tree/learning-objective-trees.page.vue'),
  }, {
    path: '/learning-objective-trees/create',
    name: 'learning-objective-tree-create',
    component: () => import( /* webpackChunkName: "learning-objective-edit.page" */ './pages/learning-objective-tree/learning-objective-tree-create.page.vue'),
  }, {
    path: '/learning-objective-trees/:id',
    name: 'learning-objective-tree-details',
    component: () => import( /* webpackChunkName: "learning-objective-details.page" */ './pages/learning-objective-tree/learning-objective-tree-details.page.vue'),
  }, {
    path: '/learning-objective-trees/:id/edit',
    name: 'learning-objective-tree-edit',
    component: () => import( /* webpackChunkName: "learning-objective-edit.page" */ './pages/learning-objective-tree/learning-objective-tree-edit.page.vue'),
  },

  // 404 page needs to be last in routes array
  {path: '*', name: '404', component: () => import( /* webpackChunkName: "404.page" */ './pages/404.page.vue')},
];

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
