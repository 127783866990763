import {i18n} from '@cat-europe/common';
import deepmerge from 'deepmerge';

export const de = deepmerge(i18n.de, {
  label: {
    allItems: 'Alle {items}',
    questionsNotInCatalogue: 'Nur Fragen nicht in Katalog',
    browser: 'Browser',
    treeView: 'Baumansicht',
    search: 'Suche',
    questionText: 'Fragentext',
    answerText: 'Antworttext',
    contains: 'Beinhaltet',
    startsWith: 'Beginnt mit',
    endsWith: 'Endet mit',
    masterData: 'Stammdaten',
    exclusiveForLicences: 'Ausschließlich für Lizenzen',
    fullSizeTable: 'Breite Tabelle',
    maxDifficulty: 'max. Schwierigkeit (100)',
    minDifficulty: 'min. Schwierigkeit (0)',
    maxTotalAnswers: 'max. Antworten',
    minTotalAnswers: 'min. Antworten',
    hasFinalTest: 'Hat Final Test',
  },
  p: {
    questionsSelected: 'Frage ausgewählt | Fragen ausgewählt',
    level: 'Ebene | Ebenen',
    list: 'Liste | Listen',
    localization: 'Lokalisierung | Lokalisierungen',
  },
  action: {
    changeResultCols: 'Ergebnisspalten ändern',
    toggleFullscreen: 'Vollbild-Modus ein-/ausschalten',
    toggleFullsize: 'Maximalgröße ein-/ausschalten',
    copyReference: 'Referenz kopieren',
    resetTableStyles: 'Tabellen und Zelleneigenschaften zurücksetzen',
    startMockTest: 'Beispieltest Starten',
  },
  translation: {
    de: 'Deutsche Übersetzung',
    en: 'Englische Übersetzung',
  },
  text: {
    deleteAnswerWarning: 'Sind sie sicher, dass sie die letzte Antwort aller Lokalisierungen löschen wollen?',
    changeQuestionCatalogueLOTreeDialog: 'Das ändern des Lernzielbaums entfernt alle aktuell in diesem Fragenkatalog gespeicherten Fragen. Den Lernzielbaum trotzdem ändern?',
    resourceLOsFromContentBlock: 'Es wird {count} Lernziel von dem Content-Block für diese Resource übernommen | Es werden {count} Lernziele von dem Content-Block für diese Resource übernommen ',
  },
  error: {
    OUTDATED_RESOURCE: `Diese Version kann nicht wiederhergestellt werden, da sie eine veraltete Ressource enthält.
Um diese Ressource wiederherzustellen, benutze bitte die Ressourcen Detailansicht.
Um andere Elemente dieser Version wiederherzustellen, benutze bitte die entsprechenden Time Slip Symbole.`,
  },
  notify: {
    copyItemToClipboardSuccess: '"{item}" wurde in die Zwischenablage kopiert ',
  },
  msg: {
    questionDifficultyTooltip: '{incorrect} von {total} mal falsch beantwortet',
  },
});
