export enum CommentableType {
  Ticket = 'ticket',
  Task = 'task',
  User = 'user',
  Resource = 'resource',
  Script = 'script',
  ContentBlock = 'content-block',
  LearningObjective = 'learning-objective',
  Organization = 'organization',
  Order = 'order',
  Question = 'question',
  Module = 'module',
}
