export const en = {
  p: {
    none: 'None | None',
    progressTest: 'Progress Test | Progress Tests',
    finalTest: 'Final Test | Final Tests',
    preExam: 'Pre-Exam | Pre-Exams',
    questionPool: 'Question Pool | Question Pools',
    try: 'Try | Tries',
    attempt: 'Attempt | Attempts',
    test: 'Test | Tests',
    module: 'Module | Modules',
    reference: 'Reference | References',
    annotation: 'Annotation | Annotations',
    entity: 'Entity | Entities',
    task: 'Task | Tasks',
    contentBlockLocalization: 'Content Block Localization | Content Block Localizations',
    inlineResource: 'Inline Resource | Inline Resources',
    textBox: 'Text Box | Text Boxes',
    titlePage: 'Title Page | Title Pages',
    structure: 'Structure | Structures',
    page: 'Page | Pages',
    relevantLO: 'Relevant Learning-Objective | Relevant Learning-Objectives',
    irrelevantLO: 'Irrelevant Learning-Objective | Irrelevant Learning-Objectives',
    explanation: 'Explanation | Explanations',
    proseBlock: 'Prose Block | Prose Blocks',
    internalComment: 'Internal Comment | Internal Comments',
    publicComment: 'Public Comment | Public Comments',
    draft: 'Draft | Drafts',
    pdf: 'PDF | PDFs',
    audioFile: 'Audio File | Audio Files',
    second: 'Second | Seconds',
    document: 'Document | Documents',
    country: 'Country | Countries',
    invite: 'Invite | Invites',
    filter: 'Filter | Filters',
    overdueOrder: 'Overdue Order | Overdue Orders',
    subTotal: 'Sub Total | Sub Totals',
    discount: 'Discount | Discounts',
    tax: 'Tax | Taxes',
    purchase: 'Purchase | Purchases',
    item: 'Item | Items',
    product: 'Product | Products',
    questionCatalogue: 'Question Catalogue | Question Catalogues',
    detail: 'Detail | Details',
    child: 'Child | Children',
    parent: 'Parent | Parents',
    examinationAuthority: 'Examination Authority | Examination Authorities',
    relation: 'Relation | Relations',
    type: 'Type | Types',
    educationType: 'Education-Type | Education-Types',
    course: 'Course | Courses',
    questionSource: 'Questionsource | Questionsources',
    accessPass: 'Access Pass | Access Passes',
    education: 'Education | Educations',
    day: 'Day | Days',
    week: 'Week | Weeks',
    month: 'Month | Months',
    year: 'Year | Years',
    account: 'Account | Accounts',
    partner: 'Partner | Partners',
    platform: 'Platform | Platforms',
    review: 'Review | Reviews',
    weighting: 'Weighting | Weightings',
    minute: 'Minute | Minutes',
    hour: 'Hour | Hours',
    correctAnsweredQuestion: 'Correct Answered Question | Correct Answered Questions',
    incorrectAnsweredQuestion: 'Incorrect Answered Question | Incorrect Answered Questions',
    answeredQuestion: 'Answered Question | Answered Questions',
    unansweredQuestion: 'Unanswered Question | Unanswered Questions',
    message: 'Message | Messages',
    solution: 'Solution | Solutions',
    availableQuestion: 'Available Question | Available Questions',
    learningObjectiveTree: 'Learning Objective Tree | Learning Objective Trees',
    warning: 'Warning | Warnings',
    error: 'Error | Errors',
    questionnaireTemplate: 'Questionnaire Template | Questionnaire Templates',
    questionnaire: 'Questionnaire | Questionnaires',
    examination: 'Examination | Examinations',
    point: 'Point | Points',
    statistic: 'Statistic | Statistics',
    question: 'Question | Questions',
    answer: 'Answer | Answers',
    organizationProfile: 'Organization Profile | Organization Profiles',
    userProfile: 'User Profile | User Profiles',
    authorProfile: 'Author Profile | Author Profiles',
    contactProfile: 'Contact Profile | Contact Profiles',
    eLearningAccount: 'eLearning Account | eLearning Accounts',
    organizationAccount: 'Organization Account | Organization Accounts',
    ltmsAccount: 'LTMS Account | LTMS Accounts',
    media: 'Media | Media',
    rating: 'Rating | Rating',
    version: 'Version | Versions',
    student: 'Student | Student',
    author: 'Author | Authors',
    licence: 'Licence | Licences',
    attachment: 'Attachment | Attachments',
    comment: 'Comment | Comments',
    contentBlock: 'Content Block | Content Blocks',
    script: 'Script | Scripts',
    content: 'Content | Contents',
    change: 'Change | Changes',
    user: 'User | Users',
    organization: 'Organization | Organizations',
    language: 'Language | Languages',
    profile: 'Profile | Profiles',
    order: 'Order | Orders',
    invoice: 'Invoice | Invoices',
    notification: 'Notification | Notifications',
    file: 'File | Files',
    note: 'Note | Notes',
    name: 'Name | Names',
    tag: 'Tag | Tags',
    ticket: 'Ticket | Tickets',
    resource: 'Resource | Resources',
    image: 'Image | Images',
    video: 'Video | Videos',
    audio: 'Audio | Audios',
    url: 'URL | URLs',
    source: 'Source | Sources',
    learningObjective: 'Learning Objective | Learning Objectives',
    event: 'Event | Events',
    todo: 'Todo | Todos',
    translation: 'Translation | Translations',
  },
  field: {
    unlimited: 'Unlimited',
    passRate: 'Pass Rate',
    timeLimit: 'Time Limit',
    coolDown: 'Cool Down',
    questionLimit: 'Question Limit',
    totalAnswers: 'Total Answers',
    difficulty: 'Difficulty',
    reviewedAt: 'Reviewed at',
    origin: 'Origin',
    isArchived: 'Archived',
    contactEmail: 'Email (Contact)',
    supportEmail: 'Email (Support)',
    isScriptRelevant: 'Script-relevant',
    reducedTax: 'Reduced Tax',
    vatExempt: 'VAT exempt',
    orderStatus: 'Order Status',
    invoiceStatus: 'Invoice Status',
    customerNumber: 'Customer Number',
    taxId: 'Tax ID',
    tax: 'Tax',
    recipient: 'Recipient',
    allowInvoice: 'Allow Invoice',
    discount: 'Discount',
    price: 'Price',
    jobTitle: 'Job-Title',
    website: 'Website',
    certificateNumber: 'Certificate #',
    primaryContact: 'Primary Contact',
    gender: 'Gender',
    licenceType: 'Licence Type',
    educationStartDate: 'Education Start Date',
    educationEndDate: 'Education End Date',
    certificateDate: 'Certification Date',
    preliminaryCertificateDate: 'Preliminary Certification Date',
    publishedState: 'Published State',
    birthDate: 'Birth Date',
    tokenVersion: 'Token Version',
    lastLoginAt: 'Latest Activity',
    phone: 'Phone',
    fax: 'Fax',
    position: 'Position',
    login: 'Login',
    id: 'Id',
    sourceReference: 'Source Reference',
    name: 'Name',
    number: 'Number',
    username: 'Username',
    note: 'Note',
    updatedAt: 'Updated at',
    updatedBy: 'Updated by',
    createdAt: 'Created at',
    startedAt: 'Started at',
    endedAt: 'Ended at',
    actions: 'Actions',
    email: 'Email',
    personalEmail: 'Email (private)',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    password: 'Password',
    passwordConfirm: 'Password confirmation',
    date: 'Date',
    data: 'Data',
    type: 'Type',
    user: 'User',
    logo: 'Logo',
    image: 'Image',
    object: 'Object',
    subject: 'Subject',
    language: 'Language',
    description: 'Description',
    title: 'Title',
    subTitle: 'Sub Title',
    content: 'Content',
    title_: 'Title {suffix}',
    text: 'Text',
    source: 'Source',
    body: 'Body',
    headline: 'Headline',
    firstName: 'First Name',
    lastName: 'Last Name',
    country: 'Country',
    state: 'State',
    streetName: 'Street Name',
    streetAddress: 'Street Address',
    phoneNr: 'Phone Number',
    zipCode: 'Zip Code',
    city: 'City',
    assignedUser: 'Assigned User',
    creator: 'Creator',
    picture: 'Picture',
    headlineLevel: 'Headline Level',
    studyTime: 'Study Time',
    status: 'Status',
    version: 'Version',
    avatar: 'Avatar',
    isCorrect: 'Correct',
    isRepeatEducation: 'Repeater',
  },
  label: {
    limit: 'Limit',
    unlimited: 'Unlimited',
    pageBreak: 'Page Break',
    none: 'None',
    myTasks: 'My Tasks',
    createdByMe: 'Created by me',
    paragraphMarks: 'Paragraph Marks',
    sideImageTemplate: 'Side Image Template',
    contentBlockList: 'Content-Blocks that treat this Learning-Objective:',
    progressTestList: 'Progress-Tests that treat this Learning-Objective:',
    applicableLicences: 'Applicable for licences',
    reverseCharge: 'Reverse Charge',
    reviewedBy: 'reviewed by',
    draftList: 'Draft List',
    forApproval: 'for Approval',
    pendingDraft: 'Pending Draft',
    reviewer: 'Reviewer',
    deleted: 'Deleted',
    detailView: 'Detail View',
    hasDraft: 'Has Draft',
    exclude: 'Exclude',
    include: 'Include',
    only: 'Only',
    external: 'External',
    resetRating: 'Reset Rating',
    public: 'Public',
    emailLanguage: 'Email Language',
    at: 'at',
    for: 'for',
    createdBy: 'Created by',
    versionList: 'Versionlist',
    currentVersion: 'Current Version',
    newVersion: 'New Version',
    assignedBy: 'Assigned by',
    assignedAt: 'Assigned at',
    activatedAt: 'Activated at',
    system: 'System',
    myProducts: 'My Products',
    myAccessPasses: 'My Access Passes',
    createdFrom: 'Created from',
    createdUntil: 'Created until',
    minTotal: 'Minimum Total',
    maxTotal: ' Maximum Total',
    client: 'Client',
    clientIsNotRecipient: 'Client is not recipient',
    inventory: 'Inventory',
    amount: 'Amount',
    assigned: 'Assigned',
    activated: 'Activated',
    orderDate: 'Order Date',
    billingAddress: 'Billing Address',
    paymentDetails: 'Payment Details',
    paymentMethod: 'Payment Method',
    assignedItems: 'Assigned {items}',
    availableItems: 'Available {items}',
    filter: 'Filter',
    origin: 'Origin',
    total: 'Total',
    progressOverview: 'Progress Overview',
    processed: 'Processed',
    feedbackType: 'Type of Feedback',
    imprint: 'Imprint',
    current: 'Current',
    lastSubmission: 'Last Submission',
    interfaceLanguage: 'Interface Language',
    analysis: 'Analysis',
    validity: 'Validity',
    validFrom: 'Valid from',
    validUntil: 'Valid until',
    expiresAt: 'Expires At',
    startDate: 'Start Date',
    endDate: 'End Date',
    cancelDate: 'Cancel Date',
    trainingStartDate: 'Training Start Date',
    trainingEndDate: 'Training End Date',
    eLearning: 'eLearning',
    performance: 'Performance',
    isActive: 'Active',
    account: 'Account',
    selectedItems: '{items} selected',
    totalItems: '{items} total',
    endedQuestionnaireInstances: 'Ended Questionnaires',
    activeQuestionnaireInstances: 'Active Questionnaires',
    tableOfContents: 'Table of Contents',
    listOfFigures: 'List of Figures',
    listOfFormulas: 'List of Formulas',
    listOfTables: 'List of Tables',
    listOfSources: 'List of Sources',
    reasonForChange: 'Reason for Change',
    duration: 'Duration',
    overview: 'Overview',
    feedback: 'Feedback',
    timeLimit: 'Timelimit',
    settings: 'Settings',
    selection: 'Selection',
    questionSelection: 'Question Selection',
    summary: 'Summary',
    training: 'Training',
    changeHistory: 'Change History',
    filename: 'Dateiname',
    preview: 'Preview',
    searchIn: 'Search in',
    isExternal: 'Hosted externally',
    details: 'Details',
    contact: 'Contact',
    today: 'Today',
    calendar: 'Calendar',
    info: 'Info',
    pdf: 'Pdf',
    all: 'All',
    assignedToMe: 'Assigned to me',
    fromMe: 'From Me',
    questionTrainer: 'Questiontrainer',
    oClock: 'o\'clock',
    never: 'Never',
    count: 'Count',
    by: 'by',
  },
  calendarType: {
    day: 'Day',
    week: 'Week',
    month: 'Month',
    '4day': '4 Days',
  },
  placeholder: {
    myNotice: 'My Note...',
    myFeedback: 'My Feedback...',
  },
  action: {
    appendItem: 'Append {item}',
    seeImgName: '[See Image {name}]',
    seeImg: '[See Image]',
    toggleItem: 'Toggle {item}',
    togglePreventItem: '{item} prevent/allow',
    insertItem: 'Insert {item}',
    referenceItem: 'Reference {item}',
    inviteUser: 'Invite User',
    sendInvite: 'Send Invite',
    searchFor: 'Search For',
    expandAll: 'Expand all',
    collapse: 'Collapse',
    expand: 'Expand',
    collapseAll: 'Collapse all',
    hideUnusedCBs: 'Hide unused Contentblocks.',
    showUnusedCBs: 'Show unused Contentblocks.',
    reload: 'Reload',
    refresh: 'Refresh',
    accept: 'Accept',
    reject: 'Reject',
    rejectDraft: 'Reject Draft',
    submitDraft: 'Submit as Draft',
    open: 'Open',
    download: 'Download',
    browseItems: 'Browse {items}',
    addItemToTarget: 'Add {item} to "{target}"',
    create: 'Create',
    executeOrder: 'Execute Order',
    ok: 'OK',
    activate: 'Activate',
    confirmAssignment: 'Confirm Assignment',
    copyToClipboard: 'Copy to clipboard',
    copyItemToClipboard: 'Copy {item} to Clipboard',
    orderAtNoCost: 'Order at no Cost',
    remove: 'Remove',
    reset: 'Reset',
    exitItem: 'Exit {item}',
    submitFeedback: 'Give Feedback',
    provideFeedbackForItem: 'Report {item} Issue',
    selectNewPassword: 'Select a new Password',
    resetPassword: 'Reset Password',
    apply: 'Apply',
    filter: 'Filter',
    unbookmarkItem: 'Unbookmark {item}',
    bookmarkItem: 'Bookmark {item}',
    add: 'Add',
    updateItem: 'Update {item}',
    requestItem: 'Request {item}',
    hideItem: 'Hide {item}',
    checkItem: 'Check {item}',
    writeItem: 'Write {item}',
    scaleUp: 'Scale Up',
    scaleDown: 'Scale Down',
    openInNewTab: 'Open in new Tab',
    importFrom: 'Import from {from}',
    select: 'Select',
    end: 'End',
    endItem: 'End {item}',
    removeItem: 'Remove {item}',
    save: 'Save',
    saveAndEnd: 'Save and End',
    dontSaveAndEnd: 'End without Save',
    saveAs: 'Save as {as}',
    check: 'Check',
    start: 'Start',
    prev: 'Previous',
    next: 'Next',
    import: 'Import',
    upload: 'Upload',
    uploadItem: 'Upload {item}',
    update: 'Update',
    reply: 'Reply',
    close: 'Close',
    insert: 'Insert',
    seeItem: 'See {item}',
    addItem: 'Add {item}',
    selectItem: 'Select {item}',
    selectAll: 'Select all',
    createItem: 'Create {item}',
    translateItem: 'Translate {item}',
    changeItem: 'Change {item}',
    editItem: 'Edit {item}',
    deleteItem: 'Delete {item}',
    importItem: 'Import {item}',
    enableItem: 'Enable {item}',
    openItem: 'Open {item}',
    showItem: 'Show {item}',
    generateItem: 'Generate {item}',
    cancelItem: 'Cancel {item}',
    switchLanguage: 'Switch Language',
    edit: 'Edit',
    submit: 'Submit',
    confirm: 'Confirm',
    cancel: 'Cancel',
    logout: 'Logout',
    login: 'Login',
    delete: 'Delete',
    back: 'Back',
    search: 'Search',
    feedback: 'Feedback',
    resetFilter: 'Reset Filter "{query}"',
    selected: 'selected',
    moveItemUp: 'Move {item} up',
    moveItemDown: 'Move {item} down',
  },
  change: {
    by: {
      delete: 'Deleted by',
      update: 'Updated by',
      create: 'Created by',
    },
  },
  notify: {
    success: 'Success',
    error: 'Error',
    info: 'Info',
    serverError: 'Server Error',
    clientError: 'Client Error',
    unauthorized: 'Unauthorized',
    unknownError: 'Unknown Error',
    unknownEmail: 'Unknown Email Address',
    changeItemSuccess: '{item} has been changed.',
    changeItemFail: '{item} could not be changed.',
    createItemSuccess: '{item} has been created.',
    addItemsSuccess: '{items} have been added.',
    addItemSuccess: '{item} has been added.',
    removeItemsSuccess: '{items} have been removed.',
    removeItemSuccess: '{item} has been removed.',
    updateItemSuccess: '{item} has been updated.',
    updateItemsSuccess: '{items} have been updated.',
    deleteItemSuccess: '{item} has been deleted.',
    saveItemSuccess: '{item} has been saved.',
    contentBlocksRemovedFromSelection: '{count} object has been removed from the selection, because it already exists in this document structure. | {count} objects have been removed from the selection, because they already exist in this document structure.',
    contentBlocksAddedToNode: '{count} entry added to {node}. | {count} entries added to {node}.',
    contentBlocksAddedToRoot: '{count} entry added to the document root. | {count} entries added to the document root.',
  },
  language: {
    global: 'Global',
    de: 'German',
    en: 'English',
    ro: 'Romanian',
    si: 'Slovene',
    nl: 'Dutch',
    fr: 'French',
  },
  email: {
    sendNotification: 'Notify per E-Mail',
    notificationText: 'Notification Text',
    template: {
      createUser: `Hello {{userName}},
You can login at {{url}} with the following credentials:

Email: {{email}}
Password: {{password}}

Enjoy!`,
    },
  },
  common: {
    notYetOnline: 'Not Yet Online',
    isUploading: 'Is Uploading...',
  },
  changelog: {
    action: {
      create: 'has {0} {1} created',
      update: 'has {0} {1} updated',
      remove: 'has {0} {1} deleted',
    },
  },
  dialog: {
    changePassword: {
      title: 'Change Password',
      text: 'The new Password need to have at least {minLength} Characters',
    },
    delete: {
      title: 'Confirm Delete',
      text: 'Are you sure that you want to delete this element?',
    },
    feedback: {
      title: 'Give Feedback',
      text: 'Craete a ticket and describe your concern',
    },
    version: {
      title: 'Confirm Change',
      text: 'Is the update a Major-, Minor-, oder Patch-Level Update?',
    },
    confirmLoDeselect: {
      title: 'Remove Connection',
      text: 'Are you sure, that you want to remove the connection to Learning Objective "{lo}" and all Learning Objectives contained therein?',
    },
    changeDraft: 'Do you really want to change the pending draft?\n\nThis change cannot be undone.',
    removeContentBlock: {
      title: 'ContentBlock entfernen',
      text: 'Are you sure you want to remove this ContentBlock "{cb}" and any contained ContentBlocks?',
    },
    unsavedChanges: {
      title: 'Unsaved Changes',
      text: 'This Content Block contains unsaved changes.\n\nReally discard these changes?',
    },
  },
  versionType: {
    major: {
      title: 'Major Change',
      text: 'Content was newly created or completely revised. Change of Learning Objective.',
    },
    minor: {
      title: 'Minor Change',
      text: 'Content was modified or corrected. Resource added, changed, or removed.',
    },
    fix: {
      title: 'Fix',
      text: 'Improvements to grammar or style without changes to content. Changes to Master Data. Changes to Licenses. Addition of new Localizations.',
    },
    draft: {
      title: 'Draft',
      text: 'Submit this Change as a Draft for review.',
    },
  },
  msg: {
    contentBlockHasNoRelatedQuestions: 'This Content Block covers no Learning Objective that has Questions assigned to it.',
    contentBlockHasNoRelatedLearningObjectives: 'This Content Block has no Learning Objective assigned.',
    noChangesAvailable: 'No Changes Available',
    addUserAndSendInvite: 'Create User and notify via Email',
    cbHasExcessiveLOs: 'This Content-Block treats at least one Learning-Objective, that is not relevant for the selected licence.',
    pendingDraft: 'This Entity cannot be edited, until the pending Draft has been approved or rejected.',
    notYetRated: 'Content not yet rated',
    testNotYetExecuted: 'Test not yet executed ',
    questionArchived: 'This Question has been archived.',
    questionArchivedText: `This Question has been archived after this Questionnaire was created.

It can no longer be answered or repeated in new Questionnaires and will not count towards overall progression.`,
    pageNotFound: 'The page "{page}" could not be found',
    passwordChangeSuccess: 'The password has been changed successfully',
    noItemsAvailable: 'No {items} available',
    noDataAvailable: 'No data available',
    resetPasswordLinkSentToEmail: 'Link to reset password has been sent to {email}',
    organizationCannotPlaceOrders: 'This Organization will not be able to place paid orders until the required address details are added.<br><br>{action} anyway?',
    organizationCountryWarning: 'This Organization will not be able to place paid orders, because the selected country is not activated.<br><br>{action} anyway?',
    organizationMissingTaxIdWarning: 'Organizations without a VAT ID are not be able to place paid orders if they are located in a country with Reverse Charge procedure.<br><br>{action} organization without VAT ID anyway?',
    noDraftsSubmitted: 'No Drafts have been submitted',
    noRelatedLOsForLicence: 'There are not related LearningObjectives for this Licence',
    noRelevantLOAssigned: 'No relevant Learning-Objective assigned',
    missingTranslation: 'Missing Translation',
    excludedLicence: 'Excluded for Licence',
    invalidParent: 'Invalid Parent',
    excessiveLearningObjectives: 'Excessive Learning Objectives',
    scriptIsMissingTranslationForCB: 'PDF cannot be generated, because the script has at least one content-block without localization for the selected Language.',
  },
  suffix: {
    de: ' (DE)',
    en: ' (EN)',
  },
  state: {
    published: 'Published',
    unpublished: 'Unpublished',
    edited: 'Edited',
  },
  questionnaireType: {
    training: 'Training',
    progress: 'Progress-Test',
    final: 'Final-Test',
    preExam: 'Pre-Exam',
  },
  productType: {
    accessPass: 'Access Pass',
    education: 'Education',
  },
  publishedState: {
    pending: 'Pending',
    published: 'Published',
    unpublished: 'Unpublished',
  },
  ticketType: {
    support: 'Support',
    task: 'Task',
    feedback: 'Feedback',
    review: 'Review',
    graphics: 'Graphics',
    translation: 'Translation',
    submission: 'Submission',
  },
  taskStatus: {
    open: 'Open',
    completed: 'Completed',
    inProgress: 'In Progress',
  },
  taskType: {
    review: 'Review',
    default: 'Default',
    translation: 'Translation',
    graphic: 'Graphic',
    controlling: 'Controlling',
  },
  feedbackType: {
    content: 'Content Feedback',
    technical: 'Technical Issue',
    featureRequest: 'Feature Request',
    other: 'Other',
  },
  educationType: {
    presence: 'Presence',
    distance: 'Distance',
  },
  accessPassType: {
    questionTrainer: 'QuestionTrainer',
  },
  licenceType: {
    general: 'General',
    helicopter: 'Helicopter',
    aeroplane: 'Aeroplane',
    balloon: 'Balloon',
  },
  resourceType: {
    image: 'Image',
    video: 'Video',
    audio: 'Audio',
    pdf: 'PDF',
    sketchFab: '3D Model (SketchFab)',
    file: 'File',
    website: 'Website',
  },
  questionSource: {
    aircademy: 'Aircademy',
  },
  text: {
    testQuestionsCount: 'This test contains {count} question | This test contains {count} questions',
    questionFeedback: 'Here you can provide Feedback for a Question',
    changeLanguageInfo: 'Select your preferred language for automated emails and contact with our customer support.',
    confirmDeleteAccount: 'Do you really want to remove this Account?',
    confirmDeleteProfile: 'Do you really want to remove this Profile?',
    confirmDeleteComment: 'Do you really want to delete this Comment?',
  },
  q: {
    passwordLost: 'Forgot password?',
  },
  paymentMethod: {
    paypal: 'PayPal',
    invoice: 'Invoice',
    freeOfCharge: 'Free of Charge',
  },
  orderStatus: {
    paid: 'Paid',
    open: 'Open',
    overdue: 'Overdue',
    cancelled: 'Cancelled',
    refunded: 'Refunded',
    freeOfCharge: 'Free of Charge',
  },
  invoiceStatus: {
    open: 'Open',
    created: 'Created',
    noInvoice: 'No Invoice',
  },
  questionStatus: {
    active: 'Active',
    archived: 'Archived',
  },
  analysis: {
    seriesName: {
      correct: 'Correct',
      incorrect: 'Incorrect',
      unanswered: 'Unanswered',
    },
  },
  rule: {
    mustMatchPassword: 'Must match Password',
    notEmpty: 'Must not be empty',
    positiveNumber: 'Must be a positive number',
    duplicateSelection: 'Duplicate Selection',
  },
  ticketStatus: {
    open: 'Open',
    closed: 'Closed',
    inProgress: 'In Progress',
  },
  tooltip: {
    like: 'Like',
    dislike: 'Dislike',
  },
  draftStatus: {
    open: 'Pending',
    accepted: 'Accepted',
    rejected: 'Rejected',
  },
  color: {
    red: 'Red',
    green: 'Green',
    yellow: 'Yellow',
  },
  testType: {
    final: 'Final Test',
    progress: 'Progress Test',
    preExam: 'Pre-Exam',
  },
  scriptLOState: {
    progressBar: '{count} of {total} Learning-Objectives are {color} ({percentage})',
    parent: {
      red: 'This Learning-Objective is Red, because all subordinated Learning-Objectives are Red.',
      orange: 'This Learning-Objective is Orange, because at least one subordinated Learning-Objective is Red or Orange.',
      yellow: 'This Learning-Objective is Yellow, because at least one subordinated Learning-Objective is Yellow.',
      green: 'This Learning-Objective is Green, because all subordinated Learning-Objectives are Green.',
    },
    leaf: {
      red: 'This Learning-Objective is Red, because it is not treated by any numbered Content-Block.',
      green: 'This Learning-Objective is Green, because it is treated by exactly one numbered Content-Block',
      yellowMultipleCBs: 'This Learning-Objective is Yellow, because it is treated by multiple numbered Content-Blocks',
      yellowExcludedParent: 'This Learning-Objective is Yellow, because it is treated by a numbered Content-Block, whose parent is excluded for the selected Licence.',
      yellowNotTranslatedCBs: 'This Learning-Objective is Yellow, because it is treated by a numbered Content-Block that has no localization for the selected Language.',
    },
  },
};
