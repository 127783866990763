import {i18n} from '@cat-europe/common';
import deepmerge from 'deepmerge';

export const en = deepmerge(i18n.en, {
  label: {
    allItems: 'All {items}',
    questionsNotInCatalogue: 'Only Questions not in Catalogue',
    browser: 'Browser',
    treeView: 'Treeview',
    search: 'Search',
    questionText: 'Questiontext',
    answerText: 'Answertext',
    contains: 'Contains',
    startsWith: 'Starts with',
    endsWith: 'Ends with',
    masterData: 'Master Data',
    exclusiveForLicences: 'Exclusive for Licences',
    fullSizeTable: 'Full Size Table',
    maxDifficulty: 'Max. Difficulty (100)',
    minDifficulty: 'Min. Difficulty (0)',
    maxTotalAnswers: 'Max. Total Answers',
    minTotalAnswers: 'Min. Total Answers',
    hasFinalTest: 'Has Final Test',
  },
  p: {
    questionsSelected: 'Question selected | Questions selected',
    level: 'Level | Levels',
    list: 'List | Lists',
    localization: 'Localization | Localizations',
  },
  action: {
    changeResultCols: 'Change Result Columns',
    toggleFullscreen: 'Toggle Fullscreen',
    toggleFullsize: 'Toggle Fullsize',
    copyReference: 'Copy Reference',
    resetTableStyles: 'Reset Table und Cell attributes',
    startMockTest: 'Start Mock Test',
  },
  translation: {
    de: 'German Translation',
    en: 'English Translation',
  },
  text: {
    deleteAnswerWarning: 'Are you sure that you want to delete the last answer from all localizations?',
    changeQuestionCatalogueLOTreeDialog: 'Changing the Learning Objective Tree will remove all questions from this catalogue. Do you want to proceed anyway?',
    resourceLOsFromContentBlock: 'There is {count} learning-objective used for this resource from the current content-block | There are {count} learning-objectives used for this resource from the current content-block',
  },
  error: {
    OUTDATED_RESOURCE: `This version cannot be restored, because it contains an outdated resource.
To restore the resource, please use the resource detail view.
To restore other elements of this version, please use the respective Time Slip icons.`,
  },
  notify: {
    copyItemToClipboardSuccess: 'Copied "{item}" to Clipboard',
  },
  msg: {
    questionDifficultyTooltip: '{incorrect} of {total} times the answer was incorrect',
  },
});
